/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex } from 'theme-ui'
import { sanityFetcher } from '../helpers/sanity'
import { menuPagesQuery } from '../query/menuPages'
import MenuPageLinks from '../components/MenuPageLinks'

const Home = ({ menuPages }) => {
  return (
    <div sx={{ padding: '3.646vw 2.222vw' }}>
      <h2>
        Downstairs
      </h2>
      <MenuPageLinks menuPages={menuPages} location="downstairs" />
      <h2>
        <br />
        Upstairs
      </h2>
      <Flex
        sx={{
          flexDirection: 'row',
          gap: '5em',
        }}
      >
        <div>
          <h4>
            Left
          </h4>
          <MenuPageLinks menuPages={menuPages} location="upstairs" orientation="left" />
        </div>
        <div>
          <h4>
            Right
          </h4>
          <MenuPageLinks menuPages={menuPages} location="upstairs" orientation="right" />
        </div>
      </Flex>
    </div>
  )
}

export async function getStaticProps() {
  const menuPages = await sanityFetcher(menuPagesQuery);

  return { props: { menuPages } }
}

export default Home
